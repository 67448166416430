<template>
  <div class="st-code-input" :class="size" :data-t="dataT">
    <div class="ceils-list" :class="classes" data-t="ceils-list">
      <div class="success-icon">
        <StIcon name="check" size="32" class="icon" />
      </div>
      <input
        v-for="i in length"
        :key="i"
        ref="cells"
        class="code-input-cell"
        :class="classes"
        type="text"
        inputmode="numeric"
        pattern="\d*"
        :value="cellsValues[i - 1]"
        :disabled="disabled"
        :data-t="`code-${i}`"
        placeholder="0"
        @input="handleInputCode($event, i)"
        @click="focusInputCeil($event)"
        @focus="focusInputCeil($event)"
        @keydown="handleKeyDown($event, i)"
        @paste="handlePasteCode($event, i)"
      />
    </div>
    <StTransitionExpand>
      <p
        v-if="error && errorMessage"
        class="error-message"
        data-t="code-input-error-message"
      >
        {{ errorMessage }}
      </p>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
const RESET_CODE = 1000

const removeNonDigits = (str: string) => str.replace(/[^0-9]/g, '')

const props = withDefaults(
  defineProps<{
    modelValue?: string
    error?: boolean
    errorMessage?: string
    autofocus?: boolean
    length?: number
    dataT?: string
    success: boolean
    disabled?: boolean
    size?: 's' | 'm' | 'l'
  }>(),
  {
    modelValue: '',
    errorMessage: '',
    autocomplete: '',
    length: 6,
    autofocus: true,
    dataT: 'st-code-input',
    success: false,
    disabled: false,
    size: 'm',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur'): void
  (e: 'focus'): void
  (e: 'codeEntered'): void
}>()

const cellsValues = ref(Array(props.length).fill(''))
const classes = computed(() => ({
  warning: !!props.errorMessage,
  success: !!props.success,
}))

const cells = ref<HTMLInputElement[]>()

const size = computed(() => `size-${props.size}`)

onMounted(() => {
  if (props.autofocus) cells.value?.[0].focus()
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue === cellsValues.value.join('')) return

    const splitValue = newValue.split('')
    for (let i = 0; i < props.length; i += 1) {
      cellsValues.value.splice(i, 1, splitValue[i])
    }
    cellsValues.value = [...cellsValues.value]
  },
)

watch(
  () => cellsValues.value,
  () => {
    const inputValue = cellsValues.value.join('')
    if (inputValue === props.modelValue) return

    emit('update:modelValue', inputValue)
    if (inputValue.length === props.length) {
      emit('codeEntered')
    }
  },
)

function handleInputCode(event: Event, index: number) {
  const target = event.target as HTMLInputElement
  const value = removeNonDigits(target.value)
  target.value = ''

  cellsValues.value.splice(index - 1, 1, value[0] ?? '')
  cellsValues.value = [...cellsValues.value]

  if (value) {
    const nextCell = cells.value?.[index]
    nextCell?.focus()
  } else {
    const prevCell = cells.value?.[index - 2]
    prevCell?.focus()
  }
}
function handlePasteCode(event: ClipboardEvent, index: number) {
  const paste = removeNonDigits(event.clipboardData?.getData('text') ?? '')

  let i = 0
  for (i; i < props.length; i += 1) {
    if (i >= paste.length || index - 1 + i >= props.length) {
      break
    }
    cellsValues.value.splice(index - 1 + i, 1, paste[i])
  }
  cellsValues.value = [...cellsValues.value]
  cells.value?.[index - 2 + i].focus()

  event.preventDefault()
}
function handleKeyDown(event: KeyboardEvent, index: number) {
  const target = event.target as HTMLInputElement
  const nextCell = cells.value?.[index]
  const prevCell = cells.value?.[index - 2]

  const isEmpty = !target?.value
  const isDeleteKey = ['Backspace', 'Delete'].includes(event.code)
  const isArrowLeft = event.code === 'ArrowLeft'
  const isArrowRight = event.code === 'ArrowRight'

  if (isDeleteKey && isEmpty) {
    prevCell?.focus()
    event.preventDefault()
  } else if (isArrowRight) {
    nextCell?.focus()
    event.preventDefault()
  } else if (isArrowLeft) {
    prevCell?.focus()
    event.preventDefault()
  }
}
function focusInputCeil(event: Event) {
  const target = event.target as HTMLInputElement
  target?.select()
}

function reset() {
  cells.value?.forEach((cell) => {
    const currentCell = cell
    currentCell.value = ''
  })

  const firstCell = cells.value?.[0]
  firstCell?.focus()
  emit('update:modelValue', '')
}

watchEffect(() => {
  if (props.errorMessage) {
    setTimeout(() => {
      reset()
    }, RESET_CODE)
  }
})
</script>

<style scoped>
@keyframes swing {
  0% {
    transform: translateX(var(--spacing-100));
  }

  25% {
    transform: translateX(calc(-1 * var(--spacing-050)));
  }

  50% {
    transform: translateX(var(--spacing-050));
  }

  75% {
    transform: translateX(calc(-1 * var(--spacing-025)));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes strech {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(100%);
  }
}

.st-code-input {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.ceils-list {
  position: relative;
  display: flex;
  gap: var(--spacing-075);
  justify-content: center;

  .success-icon {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    opacity: 0;

    .icon {
      color: var(--system-success);
    }
  }

  &.success {
    .success-icon {
      opacity: 1;
      transition: all 0.6s ease-out;
    }
  }

  &.warning {
    animation: swing 0.6s linear;
  }

  &.success::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;

    border: 1px solid var(--system-success);
    border-radius: var(--border-radius-025, 2px);

    animation: strech 0.6s linear;
  }
}

.code-input-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-2xl-medium);
  color: var(--text-quaternary);
  text-align: center;
  white-space: nowrap;

  background-color: transparent;
  border: none;
  border-bottom: 3px solid var(--background-secondary);
  border-radius: var(--border-radius-025);
  outline: none;
  box-shadow: none;

  &:valid {
    color: var(--text-primary);
  }

  &:valid::placeholder {
    color: var(--text-quaternary);
  }

  &:focus {
    color: var(--text-primary);
    border-color: var(--text-link);
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.48;
  }

  &.warning {
    border-color: var(--system-error);
  }

  &.success {
    animation: hide 0.6s linear;
    animation-fill-mode: forwards;
  }
}

.st-code-input.size-s {
  .code-input-cell {
    width: 50px;
    height: 76px;
  }
}

.st-code-input.size-m {
  .code-input-cell {
    width: 59px;
    height: 76px;
  }
}

.st-code-input.size-l {
  .code-input-cell {
    width: 86px;
    height: 76px;
  }
}

.error-message {
  margin-top: var(--spacing-100);
  margin-bottom: 0;

  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
  text-align: center;
}
</style>
